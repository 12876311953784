@import "_variables";
@import "fa-color";
@import "_margins-padding";
@import "node_modules/font-awesome/css/font-awesome.min";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/vue-toastr/dist/vue-toastr";
@import "node_modules/select2/src/scss/core";
@import "node_modules/dropzone/src/dropzone.scss";
@import "_bootswatch";

@import 'partials/list';

.select2-container {
	z-index:9999;
}

html {
	-webkit-overflow-scrolling: touch; // momentum scrolling
}

body{
	
}
.sticky-wrap {
	overflow-x: auto;
	overflow-y: auto;
	position: relative;
	margin: 3em 0;
	width: 100%;
	max-height: 75vh;
}
.sticky-wrap div[class^='sticky'] {
	overflow: hidden;
}
.sticky-wrap tfoot {
	display: none;
}
.sticky-wrap div table {
	margin: 0;
	position: relative;
	width: auto; /* Prevent table from stretching to full size */
}
.sticky-wrap .sticky-thead,
.sticky-wrap .sticky-col,
.sticky-wrap .sticky-intersect {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 50;
	transition: opacity .25s ease-in-out;
}
	.sticky-wrap .sticky-thead {
		box-shadow: 0 0.25em 0.1em -0.1em rgba(0,0,0,.125);
		z-index: 100;
		width: 100%; /* Force stretch */
	}
	.sticky-wrap .sticky-intersect {
		opacity: 1;
		z-index: 150;
	}
		.sticky-wrap .sticky-intersect th {
			background-color: #666;
			color: #eee;
		}
.sticky-wrap td,
.sticky-wrap th {
	box-sizing: border-box;
}
.sticky-wrap thead th {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	color: white;
	background: #2C3E50;
}
.sticky-enabled {
	margin: 0;
	width: 100%;
}

/* Sort handlers */
.sticky-wrap.sortable thead {
	cursor: pointer;
}
.sticky-wrap.sortable thead th {
	padding-right: 3em;
	position: relative;
}
.sort-handle {
	display: block;
	position: absolute;
	top: 50%;
	right: -2em;
	width: 1em;
	height: 1em;
	margin-top: -.5em;
}
	.sort-handle:before,
	.sort-handle:after {
		content: '';
		position: absolute;
		left: 0;
		width: 0;
		height: 0;
		transition: .125s ease-in-out;
	}
		.sort-handle:before {
			border-top: .4em solid transparent;
			border-right: .5em solid transparent;
			border-bottom: .4em solid rgba(0,0,0,.25);
			border-left: .5em solid transparent;
		}
		.sort-handle:after {
			border-top: .4em solid rgba(0,0,0,.25);
			border-right: .5em solid transparent;
			border-bottom: .4em solid transparent;
			border-left: .5em solid transparent;
		}
			.sticky-intersect .sort-handle:before { border-bottom-color: rgba(255,255,255,.5); }
			.sticky-intersect .sort-handle:after  { border-top-color: rgba(255,255,255,.5); }

		.sort-default .sort-handle:before {
			opacity: 1;
			top: -0.4em;
		}
		.sort-default .sort-handle:after {
			opacity: 1;
			bottom: -0.4em;
		}
		.sort-asc .sort-handle:before {
			top: -0.1em;
		}
		.sort-asc .sort-handle:after {
			opacity: 0;
		}
		.sort-desc .sort-handle:before {
			opacity: 0;
		}
		.sort-desc .sort-handle:after {
			bottom: -0.1em;
		}

/* To hide sticky column and intersect when screen gets too narrow */
@media only screen and (max-width: 768px) {
	html {
		font-size: 14px;
	}
	header,
	#final .sticky-wrap {
		max-height: 25rem;
	}
	.sticky-col, .sticky-intersect {
		display: none;
	}
}
.max-height-100 {
	max-height: 100%;
}

.border-2 {
	border: 2px solid $gray;
}

.border-1 {
	border: 1px solid $gray;
}

.clickable {
  	pointer-events: all;
  	cursor: pointer;
}

.draggable {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
}

.white-text {
	color: $gray-light;
}

.sortable-ghost {
	opacity: .5;
	background: $brand-info;
	color: $gray-light;
}

.sortable-chosen{
	//
}

.sortable-handle {
	padding-right: 14px;
}

.block-icon{
	margin: -10px;
	padding: 10px;
}

.toast-container > div{
	border-radius: 0px !important;
	box-shadow: none !important;
	opacity: .95;
}

.toast-bottom-right{
	right: 15px;
	bottom: 15px;
}

.toast-success{
	background-color: $brand-success;
}

.decorate {
	border-left: 5px solid $light-blue !important;
}

.list-group-item {
	&:nth-child(2n) {
		background: #F9F9F9;
	}

	&:nth-child(2n - 1) {
		background: #fff;
	}
}

body .btn-primary {
  background: $light-blue;
  border-radius: 4px;
  margin-right: 4px;
  border: 0;
  font-weight: 500;

  &:hover {
    background: darken($light-blue, 4%);
  }
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fff;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
	padding-left: 18px;
}

.count-sheet__item__controls {
	.btn-sm {
		padding: 1px 9px;
	}
}

.punch-list__list-wrapper {
  li {
    border-bottom: 1px solid $light-gray;
  }
}

.note__photos__section {
  float: right;

  @media screen and (min-width:$screen-sm-min) {
	  margin-top: -27px;
	  margin-right: 195px;
	  margin-bottom: -14px;
  }
}
 
._notes-editing { 
	margin-bottom: 20px;
	margin-top: -20px;
	display: flex;
	justify-content: space-between;

	>div {
		width: 49%;
	}
}

.dropzone {
	border: 1px dashed rgba(0, 0, 0, 0.35);

	.dz-message {
		margin: 3em 0;
	}
}