//////////////////
/// MARGINS
//////////////////
.margin-0{ margin: 0 !important; }
.margin-top-0{ margin-top: 0 !important; }
.margin-right-0{ margin-right: 0 !important; }
.margin-bottom-0{ margin-bottom: 0 !important; }
.margin-left-0{ margin-left: 0 !important; }
// MARGIN 1
.margin-1{ margin: 1px !important; }
.margin-top-1{ margin-top: 1px !important; }
.margin-right-1{ margin-right: 1px !important; }
.margin-bottom-1{ margin-bottom: 1px !important; }
.margin-left-1{ margin-left: 1px !important; }
// MARGIN 2
.margin-2{ margin: 2px !important; }
.margin-top-2{ margin-top: 2px !important; }
.margin-right-2{ margin-right: 2px !important; }
.margin-bottom-2{ margin-bottom: 2px !important; }
.margin-left-2{ margin-left: 2px !important; }
// MARGIN 3
.margin-3{ margin: 3px !important; }
.margin-top-3{ margin-top: 3px !important; }
.margin-right-3{ margin-right: 3px !important; }
.margin-bottom-3{ margin-bottom: 3px !important; }
.margin-left-3{ margin-left: 3px !important; }
// MARGIN 4
.margin-4{ margin: 4px !important; }
.margin-top-4{ margin-top: 4px !important; }
.margin-right-4{ margin-right: 4px !important; }
.margin-bottom-4{ margin-bottom: 4px !important; }
.margin-left-4{ margin-left: 4px !important; }
// MARGIN 5
.margin-5{ margin: 5px !important; }
.margin-top-5{ margin-top: 5px !important; }
.margin-right-5{ margin-right: 5px !important; }
.margin-bottom-5{ margin-bottom: 5px !important; }
.margin-left-5{ margin-left: 5px !important; }
// MARGIN 10
.margin-10{ margin: 10px !important; }
.margin-top-10{ margin-top: 10px !important; }
.margin-right-10{ margin-right: 10px !important; }
.margin-bottom-10{ margin-bottom: 10px !important; }
.margin-left-10{ margin-left: 10px !important; }
// MARGIN 15
.margin-15{ margin: 15px !important; }
.margin-top-15{ margin-top: 15px !important; }
.margin-right-15{ margin-right: 15px !important; }
.margin-bottom-15{ margin-bottom: 15px !important; }
.margin-left-15{ margin-left: 15px !important; }
// MARGIN 20
.margin-20{ margin: 20px !important; }
.margin-top-20{ margin-top: 20px !important; }
.margin-right-20{ margin-right: 20px !important; }
.margin-bottom-20{ margin-bottom: 20px !important; }
.margin-left-20{ margin-left: 20px !important; }
// MARGIN 25
.margin-25{ margin: 25px !important; }
.margin-top-25{ margin-top: 25px !important; }
.margin-right-25{ margin-right: 25px !important; }
.margin-bottom-25{ margin-bottom: 25px !important; }
.margin-left-25{ margin-left: 25px !important; }
// MARGIN 30
.margin-30{ margin: 30px !important; }
.margin-top-30{ margin-top: 30px !important; }
.margin-right-30{ margin-right: 30px !important; }
.margin-bottom-30{ margin-bottom: 30px !important; }
.margin-left-30{ margin-left: 30px !important; }
//////////////////
/// PADDING
//////////////////
.padding-0{ padding: 0 !important; }
.padding-top-0{ padding-top: 0 !important; }
.padding-right-0{ padding-right: 0 !important; }
.padding-bottom-0{ padding-bottom: 0 !important; }
.padding-left-0{ padding-left: 0 !important; }
// PADDING 1
.padding-1{ padding: 1px !important; }
.padding-top-1{ padding-top: 1px !important; }
.padding-right-1{ padding-right: 1px !important; }
.padding-bottom-1{ padding-bottom: 1px !important; }
.padding-left-1{ padding-left: 1px !important; }
// PADDING 2
.padding-2{ padding: 2px !important; }
.padding-top-2{ padding-top: 2px !important; }
.padding-right-2{ padding-right: 2px !important; }
.padding-bottom-2{ padding-bottom: 2px !important; }
.padding-left-2{ padding-left: 2px !important; }
// PADDING 3
.padding-3{ padding: 3px !important; }
.padding-top-3{ padding-top: 3px !important; }
.padding-right-3{ padding-right: 3px !important; }
.padding-bottom-3{ padding-bottom: 3px !important; }
.padding-left-3{ padding-left: 3px !important; }
// PADDING 4
.padding-4{ padding: 4px !important; }
.padding-top-4{ padding-top: 4px !important; }
.padding-right-4{ padding-right: 4px !important; }
.padding-bottom-4{ padding-bottom: 4px !important; }
.padding-left-4{ padding-left: 4px !important; }
// PADDING 5
.padding-5{ padding: 5px !important; }
.padding-top-5{ padding-top: 5px !important; }
.padding-right-5{ padding-right: 5px !important; }
.padding-bottom-5{ padding-bottom: 5px !important; }
.padding-left-5{ padding-left: 5px !important; }
// PADDING 10
.padding-10{ padding: 10px !important; }
.padding-top-10{ padding-top: 10px !important; }
.padding-right-10{ padding-right: 10px !important; }
.padding-bottom-10{ padding-bottom: 10px !important; }
.padding-left-10{ padding-left: 10px !important; }
// PADDING 15
.padding-15{ padding: 15px !important; }
.padding-top-15{ padding-top: 15px !important; }
.padding-right-15{ padding-right: 15px !important; }
.padding-bottom-15{ padding-bottom: 15px !important; }
.padding-left-15{ padding-left: 15px !important; }
// PADDING 20
.padding-20{ padding: 20px !important; }
.padding-top-20{ padding-top: 20px !important; }
.padding-right-20{ padding-right: 20px !important; }
.padding-bottom-20{ padding-bottom: 20px !important; }
.padding-left-20{ padding-left: 20px !important; }
// PADDING 25
.padding-25{ padding: 25px !important; }
.padding-top-25{ padding-top: 25px !important; }
.padding-right-25{ padding-right: 25px !important; }
.padding-bottom-25{ padding-bottom: 25px !important; }
.padding-left-25{ padding-left: 25px !important; }
// PADDING 30
.padding-30{ padding: 30px !important; }
.padding-top-30{ padding-top: 30px !important; }
.padding-right-30{ padding-right: 30px !important; }
.padding-bottom-30{ padding-bottom: 30px !important; }
.padding-left-30{ padding-left: 30px !important; }