.fa-color-primary{
	color: $brand-primary;
}

.fa-color-success{
	color: $brand-success;
}

.fa-color-info{
	color: $brand-info;
}

.fa-color-warning{
	color: $brand-warning;
}

.fa-color-danger{
	color: $brand-danger;
}