.list {
    padding-left: 0;
    list-style-type: none;
    overflow: auto;
    flex: 0 1 auto;
    margin-bottom:0;
}

.list-group-item {
	&:nth-child(2n) {
		background: #F9F9F9;
	}

	&:nth-child(2n - 1) {
		background: #fff;
	}
}

